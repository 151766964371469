import { ReactElement } from 'react';
// Design system
import { Image } from '@utmb/design-system/image/Image';
import { Col } from '@utmb/design-system/box/Box';
import { Font } from '@utmb/design-system/text/font';
import { Pin } from '@utmb/design-system/pin/Pin';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
import { QuestPromoSlider } from './tabbed-promo-slider';
// Styles
import styles from './tabbed.module.scss';
// Types
import { Slice } from '@utmb/types/slice-handler';
import { SliceQuestSelectorView } from '@utmb/types/index';

const { FuturaBook } = Font;

export const QuestPromo = (props: Slice<SliceQuestSelectorView>): ReactElement | null => {
    const { quests, backgroundImage, title } = props;

    if (!quests || !quests.length) {
        return null;
    }

    return (
        <section id="participate" className={styles.top_container}>
            <Col className={styles.container}>
                <Pin.Cover xy={{ zIndex: -1 }}>
                    <Image objectFit="cover" layout="fill" image={backgroundImage} className={styles.filtered_img} />
                </Pin.Cover>
                <div className="utmb_container">
                    <FuturaBook mobile="22" desktop="32" className="font-center font-uppercase">
                        {title}
                    </FuturaBook>
                    <Spacer.size30 />
                    <QuestPromoSlider backgroundImage={backgroundImage} title={title} quests={quests} />
                </div>
            </Col>
        </section>
    );
};
