// React
import { ReactElement } from 'react';
// Types
import { SliceQuestSelectorView, QuestView } from '@utmb/types/index';
// Design System
import { Button } from 'src/design-system/button/Button';
import { Col } from 'src/design-system/box/Box';
import { Draggable } from 'src/design-system/draggable/Draggable';
import { Font } from 'src/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from 'src/design-system/link/link';
import { Separator } from 'src/design-system/separator/Separator';
import { Slides, SlidesItem, PrevButton, NextButton } from 'src/design-system/slides/Slides';
import { Spacer } from 'src/design-system/spacer/Spacer';
// Utils
import { parseMarkdown } from '@utmb/utils/markdown';
// Styles
import styles from './tabbed.module.scss';
import { useGlobal } from '@utmb/contexts/GlobalContext';

const { FuturaHeavy, FuturaBook } = Font;

export const QuestPromoSlider = (props: SliceQuestSelectorView): ReactElement => {
    const { quests } = props;

    const {
        global: {
            event: { primaryColorIsDark },
        },
    } = useGlobal();

    return (
        <Slides
            className={styles.slider}
            carouselOptions={{
                containScroll: 'keepSnaps',
                align: 'start',
                draggable: true,
                loop: false,
            }}
            header={({ embla, currentSlide }) => {
                return (
                    <>
                        <Draggable maskClassName={styles.draggable} className={styles.toolbar} draggableWidth={540}>
                            {quests.map((quest: QuestView, idx: number) => (
                                <Button.Ghost
                                    key={idx}
                                    rightAligned={isTail(quests.length, idx + 1) ? true : false}
                                    leftAligned={idx === 0 ? true : false}
                                    className={isActive(currentSlide, idx) ? styles.active : undefined}
                                    onPress={() => embla && embla.scrollTo(idx)}
                                >
                                    <FuturaBook mobile="16" as="span" className="font-uppercase">
                                        {quest.category}
                                    </FuturaBook>
                                </Button.Ghost>
                            ))}
                        </Draggable>
                        <Separator className={styles.promo_slider_separator} color="rgba(255,255,255,.2)" weight="2px" spacing="-46px auto 0 auto" />
                    </>
                );
            }}
        >
            {({ embla, currentSlide }) => (
                <>
                    {quests.map((quest: QuestView, idx: number) => (
                        <SlidesItem key={idx} className={styles.item}>
                            <div className={styles.pic}>
                                {quest.stat > 0 && (
                                    <FuturaHeavy mobile="16" className="font-traced">
                                        <span className={styles.stat}>{quest.stat}</span>
                                    </FuturaHeavy>
                                )}
                                <Image className={currentSlide + 1 === idx ? styles.dimmed : undefined} layout="fill" objectFit="cover" image={quest.picture} />
                                {embla?.canScrollPrev() && isActive(currentSlide, idx) && (
                                    <PrevButton onPress={() => embla?.scrollPrev()} className={styles.prev} />
                                )}
                                {embla?.canScrollNext() && isActive(currentSlide + 1, idx) && (
                                    <NextButton onPress={() => embla?.scrollNext()} className={styles.next} />
                                )}
                            </div>
                            <Col className={styles.card}>
                                {quest.content && (
                                    <FuturaBook mobile="14" desktop="20" className={styles.card_content} as="div">
                                        <div
                                            className={`markdown ${primaryColorIsDark ? 'primaryColorIsDark' : ''}`}
                                            dangerouslySetInnerHTML={{ __html: parseMarkdown(quest.content) }}
                                        />
                                    </FuturaBook>
                                )}
                                <Spacer />

                                {quest.link && (
                                    <Link.Button
                                        label={quest.link.label}
                                        href={`${quest.link.slug}`}
                                        followClassName={quest.link.followClass}
                                        className={styles.button}
                                        color={'#41505c'}
                                        bgcolor={'#ffffff'}
                                        variant="right"
                                    />
                                )}
                            </Col>
                        </SlidesItem>
                    ))}
                </>
            )}
        </Slides>
    );
};

// UTILS
const isActive = (current: number, priority: number): boolean => current === priority;
const isTail = (amount: number, priority: number): boolean => amount === priority;
