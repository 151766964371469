import * as React from 'react';
import { useDrag } from 'react-use-gesture';
import { animated, useSpring } from 'react-spring';
import { RenderProp } from 'src/design-system/types';
import styles from './draggable.module.scss';
import { useRect } from '@reach/rect';

interface DraggableProps {
    children?: ((...args: any[]) => React.ReactElement | null) | React.ReactNode | React.ReactElement | Element | null;
    /** Style the immediate parent of **children** aka. 'draggableContainer' */
    className?: string;
    /** Style the outer wrapper that hides any overflow*/
    maskClassName?: string;
    /** Control or monitor the progress from the outside */
    controls?: RenderProp<{
        setProgres: (x: number) => void;
        getProgress?: () => void;
    }>;
    /** Refractor progressBar to be styleable as soon as we have a use case :) */
    progressBar?: boolean;
    /** assign an estimated width to the draggable container aka. 'draggableContainer'*/
    draggableWidth: number;
}

export const Draggable = (props: DraggableProps) => {
    const { className = undefined, maskClassName = undefined, children, controls = false, progressBar = false, draggableWidth } = props;
    const maskRef = React.useRef<HTMLDivElement | null>(null);
    const [observe, _setObserve] = React.useState(true);
    const rect = useRect(maskRef, { observe });
    const progressRef = React.useRef<HTMLDivElement | null>(null);

    // GESTURE MGMT
    const [{ x }, set] = useSpring(() => ({
        x: 0,
    }));
    const bind = useDrag(({ movement: [mx], delta: [dx], tap }) => moveViewportContainer({ tap, mx }), {
        initial: () => [x?.getValue() ?? 0, 0], // every new drag starts from last drag 'point'
        bounds: { left: -draggableWidth + (rect ? rect?.width : 0), right: 0 }, // it should drag until it reaches it's width, not more.
        rubberband: 0.2,
    });
    const moveViewportContainer = (props: { down?: boolean; tap: boolean; mx: number }) => {
        const { tap, mx } = props;
        if (tap) return;
        if (rect && rect?.width >= draggableWidth) {
            // if parent has enough room we should cancel drags
            // BUT
            // user might previously dragged so we set it to initial
            return set({ x: 0 });
        } else {
            set({ x: mx });
            if (progressBar) {
                window.requestAnimationFrame(() => {
                    progressRef.current!.style.width = `${getProgress()!.toFixed(0)}%`;
                });
            }
        }
    };

    // PROGRESS UTILS
    const getProgress = () => (Math.abs(x.getValue() as number) / (draggableWidth - (rect ? rect?.width : 0))) * 100;
    const setProgres = (amount: number) => {
        set({ x: amount });
        if (progressBar) {
            window.requestAnimationFrame(() => {
                progressRef.current!.style.width = `${(Math.abs(amount / (draggableWidth - (rect ? rect?.width : 0))) * 100).toFixed(0)}%`;
            });
        }
    };
    const maskClassNames = [styles.container, maskClassName].filter(Boolean).join(' ');
    const viewportmaskClassNames = [styles.viewport, className, rect && rect?.width >= draggableWidth && styles.idle].filter(Boolean).join(' ');
    return (
        <div className={maskClassNames} ref={maskRef}>
            <animated.div
                className={viewportmaskClassNames}
                {...bind()}
                style={{
                    transform: x && x.interpolate((x) => `translate3d(${x}px,0,0)`),
                    touchAction: 'pan-y',
                    width: `${draggableWidth}px`,
                }}
            >
                {children}
            </animated.div>

            {controls &&
                controls({
                    setProgres,
                    getProgress,
                })}

            {progressBar && rect && rect?.width < draggableWidth && (
                <div className={styles.progress} aria-hidden="true">
                    <div className={styles.bar} ref={progressRef}></div>
                </div>
            )}
        </div>
    );
};
